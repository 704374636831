import { useEffect } from 'react';
import { navigate } from 'gatsby';

const EN = () => {
    useEffect(() => {
        navigate('/');
      }, []);
      return null;
}

export default EN
